import React from 'react'
import {
  CtaBar,
  LeshenPhoneCTA,
  LinkButton,
  useMapi,
} from '@leshen/gatsby-theme-leshen'
import ShoppingCart from '../../static/images/shopping-cart.svg'

const SeoCtaBar = () => {
  const { requestId } = useMapi()

  return (
    <CtaBar
      backgroundColor="primary"
      textColor="white"
      sticky
      leftBlock={
        <LeshenPhoneCTA color="light" outlined requestId={requestId}>
          Call:
        </LeshenPhoneCTA>
      }
      middleBlock={
        <LinkButton
          leftIcon={<img src={ShoppingCart} alt="shopping cart icon" />}
          color="light"
          outlined
          requestId={requestId}
          to="https://www.dish.com/clearlink/packages/?tfn={ROTATING_NUMBER}&alt_rep_id=148940"
        >
          Order Online
        </LinkButton>
      }
    />
  )
}

export default SeoCtaBar
